@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';

.container {
	background-color: $neutral-white;
	color: $neutral-black;

	padding: 3.75rem 1.5rem;

	@include md-up {
		padding-top: 7.5rem;
	}
}

.inner {
	max-width: 70rem;
	margin-inline: auto;
}

.icons {
	list-style: none;
	display: grid;
	text-align: center;
	max-width: 38rem;
	grid-template-columns: repeat(3, 1fr);
	align-items: center;
	justify-content: center;
	margin: 2rem auto;
	padding: 0;

	p {
		@include body7;
	}
	gap: 1rem;

	@include md-up {
		gap: 1rem;
		margin-bottom: 4rem;
		p {
			@include body6;
		}
	}

	span,
	svg {
		display: block;
		margin-inline: auto;
		width: 2rem;
		height: 2rem;
		margin-bottom: 1rem;
	}
}

.text {
	text-align: center;

	h2 {
		@include heading5;
	}

	p {
		@include body5;
		margin: 0.75rem auto;
	}

	@include md-up {
		h2 {
			@include heading4;
			max-width: 22ch;
			margin: 0 auto;
		}

		p {
			@include body4;
			max-width: 45ch;

			margin: 1rem auto;
		}
	}
}

.image_wrapper {
	height: 400px;
	position: relative;
	overflow: hidden;
	isolation: isolate;

	margin-top: 15px;

	@include md-up {
		height: 600px;
		margin-top: 30px;
	}
}

.tag {
	position: absolute;
	width: 12rem;
	height: 8rem;
	top: 1.5rem;
	left: 1.5rem;
	opacity: 0;

	padding: 1rem 0.75rem;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	border-radius: 10px;

	background: rgba(49, 49, 49, 0.7);
	backdrop-filter: blur(5px);

	color: $neutral-white;

	text-align: center;

	transform: translate3d(0, 3rem, 0);

	transition: all 1s ease-in-out;

	@include md-up {
		top: 8rem;
		left: 8rem;
		border-radius: 12px;
		width: 17.5rem;
		height: 12.5rem;
	}
}

.time_field {
	background-color: $neutral-1;
	border-radius: 6px;
	display: flex;
	padding: 0.25rem 0.5rem;
	align-items: center;
	width: 100%;
	justify-content: space-between;

	p {
		@include subheading6;

		&:last-child {
			font-weight: normal;
			display: inline-block;
			background-color: rgba(255, 255, 255, 0.06);
			border-radius: 4px;
			padding: 0.1875rem 0.375rem;
		}
	}

	&:last-child {
		margin-top: 0.25rem;
	}

	@include md-up {
		p {
			@include subheading5;
		}
	}
}

.tag_header {
	@include subheading5;
	margin-bottom: 0.75rem;

	@include md-up {
		@include subheading4;
		margin-bottom: 0.75rem;
	}
}

.cooling_tags {
	display: flex;
	flex-wrap: wrap;
	gap: 0.75rem;

	div {
		border-radius: 500px;
		background-color: $neutral-white;
		color: $neutral-black;
		padding: 0.375rem 1.375rem;

		&:first-child {
			background-color: rgba(255, 255, 255, 0.15);
			color: $neutral-white;
		}
	}

	p {
		@include subheading6;
	}

	@include md-up {
		p {
			@include subheading5;
		}
	}
}

.active {
	opacity: 1;
	transform: translate3d(0, 0, 0);
}

.background_image {
	z-index: -1;
	position: absolute;
	display: block;
	inset: 0;
	bottom: -50px;

	img {
		width: 100%;
		height: 100%;
		object-position: 70%;
	}
}

.cta_container {
	margin-top: 60px;
	display: flex;
	align-items: center;
	justify-content: center;

	@include sm-down {
		margin-top: 30px;
	}
}

.tabs {
	margin-top: 30px;

	legend {
		display: none;
	}

	form {
		background: transparent;

		fieldset {
			display: grid;
			grid-template-columns: repeat(3, 1fr);

			> label {
				width: 100%;

				> span {
					font-size: 20px;
					font-weight: 700;
					box-shadow: 0 2px 0 0 #e0e0e0;
				}

				input:checked + span {
					background-color: transparent;
					box-shadow: 0 2px 0 0 black;
					border: none;
					border-radius: 0;
				}
			}
		}
	}
}

.desktop_only {
	display: none;

	@include md-up {
		display: block;
	}
}

.mobile_only {
	display: block;
	margin-top: 30px;

	@include md-up {
		display: none;
	}

	:global(.accordion-panel) {
		border: none;
	}
}
