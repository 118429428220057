@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';

.container {
	isolation: isolate;
	color: $neutral-black;
	background-color: $neutral-8;

	padding: 3.75rem 1.5rem;

	> ul {
		display: flex;
		align-items: center;
		flex-direction: column;
		gap: 3.75rem;
		margin: 0;

		@include md-up {
			justify-content: center;
			flex-direction: row;
			align-items: flex-start;
			margin-inline: auto;
		}
	}

	@include md-up {
		padding: 5.5rem 4.5rem;
	}
}

.item {
	display: inline-grid;
	grid-template-columns: 2rem auto;
	gap: 1rem;

	> span {
		grid-row: 1 / span 2;
	}

	> h3 {
		@include subheading3;
		font-size: 20px;

		@include md-up {
			@include subheading2;
			font-size: 20px;
		}
		max-width: 24ch;
	}

	> p {
		@include body5;
		color: $neutral-2;
		max-width: 32ch;
		font-size: 16px;
	}

	a {
		color: inherit;
		text-decoration: underline;
	}
}

.prequalify_link {
	color: inherit;
	margin-left: 0.5ch;
	text-decoration: underline;
	cursor: pointer;
}

.shipping_list {
	padding: 1rem;

	p {
		@include body5;
	}

	ul {
		display: flex;
		flex-direction: column;
		@include body7;
		max-width: 40ch;
		margin: 1rem 0;

		list-style: inside;
	}
}

.condensed {
	@include xs-only {
		.item {
			display: flex;
			flex-direction: column;
			align-items: center;

			> span {
				width: 2rem;
			}

			> p {
				display: none;
			}
		}
	}
}
