@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background: white;
	color: $white;

	position: relative;

	text-align: center;

	isolation: isolate;
}

.cta_container {
	margin-top: 4rem;
	position: relative;

	display: flex;
	justify-content: center;
	align-items: center;

	picture,
	img {
		height: 100%;
	}

	img {
		margin: 0 auto;
		width: 100%;
		max-width: 1250px;

		height: min(43rem, 60vw);

		border-radius: 1.5rem;
	}

	@include xs-only {
		margin-top: 3rem;

		height: 20rem;

		img {
			height: 100%;
		}
	}
}

.video_button {
	color: $white !important;
	padding: 1rem !important;
	border-radius: 50% !important;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 80px;
	height: 80px;
	background: linear-gradient(135deg, rgba(125, 125, 125, 0.7) 0%, rgba(255, 255, 255, 0) 100%);
	backdrop-filter: blur(26px);

	span {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		@include button1;
		color: $white !important;
	}

	svg {
		width: 2.5rem;
		height: 2.5rem;
	}

	&:hover,
	&:focus-visible {
		background: rgba(125, 125, 125, 0.7);
	}
}

.video_container {
	display: flex;
	justify-content: center;
	align-items: center;

	position: relative;
	width: 100%;
	height: 100%;

	video {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}

.container header {
	color: black;

	h1 {
		@include headline3;
		margin: 0 auto;
		max-width: 25.6ch;
	}

	@include sm-down {
		h1 {
			@include headline5;
			margin: 0 auto;
			padding: 0;
		}
	}
}
